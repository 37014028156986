import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const NumberInput = ({ id, value, onChange }) => {
    return (
      <div className="gs-btn">
        <button
          onClick={() => onChange(id, value + 1)}
          className='incr'
          type="button"
          aria-label="Increment value"
        >
          +
        </button>        
        <input
          id={id}
          type="number"
          value={value}
          min="0"
          onChange={(e) => onChange(id, parseInt(e.target.value) || 0)}
          aria-label="Number input"
        />
        <button
          onClick={() => onChange(id, value - 1)}
          className='decr'
          type="button"
          aria-label="Decrement value"
        >
          -
        </button>
      </div>
    );
  };


const ViewSchedule = () => {

    useEffect(() => {
          document.title = `View Schedule | Agile Seekers`;
          document.getElementById('bodywrap').className='vischmain';
    },[]);

    const [values, setValues] = useState({
        gsbtn1: 0,
        gsbtn2: 0,
        gsbtn3: 0,
        gsbtn4: 0,
      });
    
      // Handle changes in the input value
      const handleValueChange = (id, newValue) => {
        setValues((prevValues) => ({
          ...prevValues,
          [id]: Math.max(newValue, 0), // Prevent negative values
        }));
      };


      const [isButtonVisible, setIsButtonVisible] = useState(true);
      const [hasClass, setHasClass] = useState(true);

      const handleClick = () => {
        setIsButtonVisible(false); 
        setHasClass(false); 
      };      
      

    return (
        <>
            <Navbar />

                <section className='vs-box'>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <Link to="/view-schedule" className='vs-back'> <Image src="assets/images/vs-back.png" className='img-fluid' alt='vs-back'/> back </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} lg={3} className='ord2'>
                                <Row>                                 
                                    <Col md={12}>
                                        <div className='vs-off'>
                                            <div>
                                                <h3>5% Off</h3>
                                                <Link to="/">
                                                    <Image src="assets/images/copy.png" className='img-fluid' alt='copy'/>
                                                </Link>
                                            </div>
                                            <p>As low as $33,933/months</p>
                                            <div className='cctxt'>
                                                <h5>Coupon code </h5>
                                                <h6>"SPRING..."</h6>
                                            </div>
                                            <div className='ccofftxt'>
                                                <h5>Coupon expires </h5>
                                                <h6>Next Tuesday</h6>
                                            </div>                                            
                                        </div>
                                        <div className='vs-google-review'>
                                            <div className='vgr-box'>
                                                <Image src="assets/images/google.png" className='img-fluid google' alt='google'/>
                                                <div className='rating_bar'>
                                                    <div className='rating-dis' style={{width: '90%'}}></div>
                                                </div>
                                            </div>     
                                            <div className='vgr-side'>   
                                                <div className='vgr-box'>
                                                    <p>4.7/5</p>                                            
                                                    <p>5624 Review </p>                                            
                                                </div>   
                                                <div className='vs-enroll'>
                                                    <div>
                                                        <div className='vs-en-left'>
                                                            <p>350,000 + Enrolled</p>
                                                        </div>
                                                        <div className='vs-en-right'>
                                                            <Image src="assets/images/rbl1.png" className='img-fluid' alt='rbl1'/>
                                                            <Image src="assets/images/rbl2.png" className='img-fluid' alt='rbl2'/>
                                                            <Image src="assets/images/rbl3.png" className='img-fluid' alt='rbl3'/>
                                                            <Image src="assets/images/rbl4.png" className='img-fluid' alt='rbl4'/>
                                                            <Image src="assets/images/rbl5.png" className='img-fluid' alt='rbl5'/>
                                                            <Image src="assets/images/rbl6.png" className='img-fluid' alt='rbl6'/>                                    
                                                        </div>
                                                    </div>    
                                                </div> 
                                            </div>                                            
                                        </div>
                                       
                                        <div className='vs-call'>
                                            <Link to="tel:+919143964396">
                                                <Image src="assets/images/cnt-phone.png" className='img-fluid' alt='cnt-phone'/>
                                                +91 91 4396 4396
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={8} lg={9} className='vsb-right'>
                                <h2>Schedules for leading SAFe 6.0 Certification </h2>
                                <Tabs
                                    defaultActiveKey="weekday"
                                    id="schtab"
                                    className="mb-3 schtab"
                                    >
                                    <Tab eventKey="weekday" title="Weekday">
                                        <Row className={hasClass ? "vsb-box" : ""}>
                                            <Col md={12}>
                                                <div className='schbox'>
                                                    <div className='scbone'>
                                                        <h3>Sep 19 - Sep 20</h3>
                                                        <div className='sctime'>
                                                            <Image src="assets/images/sctime.png" className='img-fluid' alt='sctime'/>
                                                            <p>IST 08:00 AM 05:00 PM</p>
                                                        </div>
                                                        <div className='scbatch'>
                                                            <Image src="assets/images/scbatch.png" className='img-fluid' alt='scbatch'/>
                                                            <p>Online Classroom - Weekday Batch</p>
                                                        </div>
                                                        <div className='scuser'>
                                                            <Image src="assets/images/scuser.png" className='img-fluid' alt='scuser'/>
                                                            <p>Rakesh L</p>
                                                        </div>         
                                                        <div className='scday'>
                                                            <Image src="assets/images/day.png" className='img-fluid' alt='day'/>
                                                            <p>Morning</p>
                                                        </div>                                                                                       
                                                    </div>           
                                                    <div className='scbtwo'>
                                                        <h3>44% Off</h3>
                                                        <div>
                                                            <h4>₹ 35,400</h4>
                                                            <h5>₹ 65,999</h5>
                                                        </div>
                                                        <p>As low as $33,933/months</p>
                                                    </div>
                                                    <div className='scbthr'>
                                                        <NumberInput
                                                            id="gsbtn1"
                                                            value={values.gsbtn1}
                                                            onChange={handleValueChange}
                                                        />
                                                    </div>
                                                    <div className='scbfou'>
                                                        <Link to="/">Enroll</Link>
                                                        <div>
                                                            <Image src="assets/images/rocket.png" className='img-fluid' alt='rocket'/>
                                                            <p>Selling Fast</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className='schbox'>
                                                    <div className='scbone'>
                                                        <h3>Sep 19 - Sep 30</h3>
                                                        <div className='sctime'>
                                                            <Image src="assets/images/sctime.png" className='img-fluid' alt='sctime'/>
                                                            <p>IST 08:00 AM 05:00 PM</p>
                                                        </div>
                                                        <div className='scbatch'>
                                                            <Image src="assets/images/scbatch.png" className='img-fluid' alt='scbatch'/>
                                                            <p>Online Classroom - Weekday Batch</p>
                                                        </div>
                                                        <div className='scuser'>
                                                            <Image src="assets/images/scuser.png" className='img-fluid' alt='scuser'/>
                                                            <p>Rakesh L</p>
                                                        </div>         
                                                        <div className='scday'>
                                                            <Image src="assets/images/day.png" className='img-fluid' alt='day'/>
                                                            <p>Morning</p>
                                                        </div>                                                                                       
                                                    </div>           
                                                    <div className='scbtwo'>
                                                        <h3>44% Off</h3>
                                                        <div>
                                                            <h4>₹ 35,400</h4>
                                                            <h5>₹ 65,999</h5>
                                                        </div>
                                                        <p>As low as $33,933/months</p>
                                                    </div>
                                                    <div className='scbthr'>
                                                        <NumberInput
                                                            id="gsbtn2"
                                                            value={values.gsbtn2}
                                                            onChange={handleValueChange}
                                                        />
                                                    </div>
                                                    <div className='scbfou'>
                                                        <Link to="/">Enroll</Link>
                                                        <div>
                                                            <Image src="assets/images/rocket.png" className='img-fluid' alt='rocket'/>
                                                            <p>Selling Fast</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className='schbox'>
                                                    <div className='scbone'>
                                                        <h3>Sep 19 - Sep 29</h3>
                                                        <div className='sctime'>
                                                            <Image src="assets/images/sctime.png" className='img-fluid' alt='sctime'/>
                                                            <p>IST 08:00 AM 05:00 PM</p>
                                                        </div>
                                                        <div className='scbatch'>
                                                            <Image src="assets/images/scbatch.png" className='img-fluid' alt='scbatch'/>
                                                            <p>Online Classroom - Weekday Batch</p>
                                                        </div>
                                                        <div className='scuser'>
                                                            <Image src="assets/images/scuser.png" className='img-fluid' alt='scuser'/>
                                                            <p>Rakesh L</p>
                                                        </div>         
                                                        <div className='scday'>
                                                            <Image src="assets/images/day.png" className='img-fluid' alt='day'/>
                                                            <p>Morning</p>
                                                        </div>                                                                                       
                                                    </div>           
                                                    <div className='scbtwo'>
                                                        <h3>44% Off</h3>
                                                        <div>
                                                            <h4>₹ 35,400</h4>
                                                            <h5>₹ 65,999</h5>
                                                        </div>
                                                        <p>As low as $33,933/months</p>
                                                    </div>
                                                    <div className='scbthr'>
                                                        <NumberInput
                                                            id="gsbtn3"
                                                            value={values.gsbtn3}
                                                            onChange={handleValueChange}
                                                        />
                                                    </div>
                                                    <div className='scbfou'>
                                                        <Link to="/">Enroll</Link>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className='schbox'>
                                                    <div className='scbone'>
                                                        <h3>Sep 19 - Sep 29</h3>
                                                        <div className='sctime'>
                                                            <Image src="assets/images/sctime.png" className='img-fluid' alt='sctime'/>
                                                            <p>IST 08:00 AM 05:00 PM</p>
                                                        </div>
                                                        <div className='scbatch'>
                                                            <Image src="assets/images/scbatch.png" className='img-fluid' alt='scbatch'/>
                                                            <p>Online Classroom - Weekday Batch</p>
                                                        </div>
                                                        <div className='scuser'>
                                                            <Image src="assets/images/scuser.png" className='img-fluid' alt='scuser'/>
                                                            <p>Rakesh L</p>
                                                        </div>         
                                                        <div className='scday'>
                                                            <Image src="assets/images/day.png" className='img-fluid' alt='day'/>
                                                            <p>Morning</p>
                                                        </div>                                                                                       
                                                    </div>           
                                                    <div className='scbtwo'>
                                                        <h3>44% Off</h3>
                                                        <div>
                                                            <h4>₹ 35,400</h4>
                                                            <h5>₹ 65,999</h5>
                                                        </div>
                                                        <p>As low as $33,933/months</p>
                                                    </div>
                                                    <div className='scbthr'>
                                                        <NumberInput
                                                            id="gsbtn4"
                                                            value={values.gsbtn4}
                                                            onChange={handleValueChange}
                                                        />
                                                    </div>
                                                    <div className='scbfou'>
                                                        <Link to="/">Enroll</Link>
                                                    </div>
                                                </div>
                                            </Col>     
                                            <Col md={12}>
                                                <div className='schbox'>
                                                    <div className='scbone'>
                                                        <h3>Sep 19 - Sep 30</h3>
                                                        <div className='sctime'>
                                                            <Image src="assets/images/sctime.png" className='img-fluid' alt='sctime'/>
                                                            <p>IST 08:00 AM 05:00 PM</p>
                                                        </div>
                                                        <div className='scbatch'>
                                                            <Image src="assets/images/scbatch.png" className='img-fluid' alt='scbatch'/>
                                                            <p>Online Classroom - Weekday Batch</p>
                                                        </div>
                                                        <div className='scuser'>
                                                            <Image src="assets/images/scuser.png" className='img-fluid' alt='scuser'/>
                                                            <p>Rakesh L</p>
                                                        </div>         
                                                        <div className='scday'>
                                                            <Image src="assets/images/day.png" className='img-fluid' alt='day'/>
                                                            <p>Morning</p>
                                                        </div>                                                                                       
                                                    </div>           
                                                    <div className='scbtwo'>
                                                        <h3>44% Off</h3>
                                                        <div>
                                                            <h4>₹ 35,400</h4>
                                                            <h5>₹ 65,999</h5>
                                                        </div>
                                                        <p>As low as $33,933/months</p>
                                                    </div>
                                                    <div className='scbthr'>
                                                        <NumberInput
                                                            id="gsbtn2"
                                                            value={values.gsbtn2}
                                                            onChange={handleValueChange}
                                                        />
                                                    </div>
                                                    <div className='scbfou'>
                                                        <Link to="/">Enroll</Link>
                                                        <div>
                                                            <Image src="assets/images/rocket.png" className='img-fluid' alt='rocket'/>
                                                            <p>Selling Fast</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className='schbox'>
                                                    <div className='scbone'>
                                                        <h3>Sep 19 - Sep 29</h3>
                                                        <div className='sctime'>
                                                            <Image src="assets/images/sctime.png" className='img-fluid' alt='sctime'/>
                                                            <p>IST 08:00 AM 05:00 PM</p>
                                                        </div>
                                                        <div className='scbatch'>
                                                            <Image src="assets/images/scbatch.png" className='img-fluid' alt='scbatch'/>
                                                            <p>Online Classroom - Weekday Batch</p>
                                                        </div>
                                                        <div className='scuser'>
                                                            <Image src="assets/images/scuser.png" className='img-fluid' alt='scuser'/>
                                                            <p>Rakesh L</p>
                                                        </div>         
                                                        <div className='scday'>
                                                            <Image src="assets/images/day.png" className='img-fluid' alt='day'/>
                                                            <p>Morning</p>
                                                        </div>                                                                                       
                                                    </div>           
                                                    <div className='scbtwo'>
                                                        <h3>44% Off</h3>
                                                        <div>
                                                            <h4>₹ 35,400</h4>
                                                            <h5>₹ 65,999</h5>
                                                        </div>
                                                        <p>As low as $33,933/months</p>
                                                    </div>
                                                    <div className='scbthr'>
                                                        <NumberInput
                                                            id="gsbtn3"
                                                            value={values.gsbtn3}
                                                            onChange={handleValueChange}
                                                        />
                                                    </div>
                                                    <div className='scbfou'>
                                                        <Link to="/">Enroll</Link>
                                                    </div>
                                                </div>
                                            </Col>                                                                                                                                                   
                                        </Row>
                                        {isButtonVisible && (
                                        <Row>
                                            <Col md={12} className='lpvm-btn'>
                                                <Link onClick={handleClick}>View More</Link>
                                            </Col>
                                        </Row>
                                        )}                                        
                                    </Tab>
                                    <Tab eventKey="weekend" title="Weekend">
                                        Tab content for Weekend
                                    </Tab>
                                    <Tab eventKey="month" title="Month">
                                        Tab content for Month
                                    </Tab>
                                </Tabs>                                

                            </Col>                            
                        </Row>
                    </Container>
                </section>

            <Footer />

        </>
    )        

}
    export default ViewSchedule;    