import React, { useEffect } from 'react';
import {Container, Row, Col, Image, Form, Button} from 'react-bootstrap';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";

const Contact = () => {

    useEffect(() => {
          document.title = `Contact Us | Agile Seekers`;
          document.getElementById('bodywrap').className='contactmain';
    },[]);




    return (
        <>
            <Navbar />

            <section className='contact-banner'>
                <Container fluid>
                    <Row>
                        <Col className='px-0 contimg'>
                            <h2>CONNECT WITH US!</h2>
                            <Image src="assets/images/contact.jpg" className='img-fluid' alt='contimg'/>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='contact-form'>
                <Container>
                    <Row>
                        <Col md={6} className='cf-left'>
                            <Col>
                                <h3>Contact Us</h3>
                                <Form>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="fname">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter First Name" />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="lname">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Last Name" />
                                        </Form.Group>
                                    </Row>

                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter your email" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="phone">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="text" placeholder="Enter your contact number" />
                                    </Form.Group>

                                    <Row className="mb-3">
                                        <Form.Group className="mb-3" controlId="message">
                                            <Form.Label>Messages</Form.Label>
                                            <Form.Control as="textarea" rows={5} placeholder='Messages'/>
                                        </Form.Group>
                                    </Row>

                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>

                                </Form>                            
                            </Col>
                        </Col>
                        <Col md={6} className='cf-right'>
                            <Image src="assets/images/contact-us.jpg" className='img-fluid' alt='contact'/>
                        </Col>
                    </Row>
                </Container>    
            </section>

            <Footer />

        </>
    )        

}
    export default Contact;    