import React, { useEffect } from 'react';
import {Container, Row, Col, Image, Form, Button} from 'react-bootstrap';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

const Blog = () => {

    useEffect(() => {
          document.title = `Blog | Agile Seekers`;
          document.getElementById('bodywrap').className='blogmain';
    },[]);




    return (
        <>
            <Navbar />

                <section className='blog-search'>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <h3>Agile Seeker Resources</h3>
                                <p>Explore insights, tips, and articles written by experts in a range of professional domains.</p>
                                <Form>
                                    <Col className='fr-search'>
                                        <Row>
                                            <Col xs="auto" className='pe-0'>
                                                <Button>
                                                    <Image src="assets/images/search.png" className='img-fluid' alt='search'/>      
                                                </Button>                                                
                                            </Col>
                                            <Col className='ps-0'>
                                                <Form.Control plaintext placeholder="search" />
                                            </Col>                                        
                                        </Row>
                                    </Col>
                                </Form>         
                            </Col>                            
                            <Col md={6}>
                                <Image src="assets/images/blog.jpg" className='img-fluid' alt='blog'/>                                  
                            </Col>
                        </Row>
                    </Container>
                </section>


                <section className='blog-bot-sec'>
                    <Container>
                        <Row className='lpbox'>
                            <Col md={12}>
                                <h3>Latest Posts</h3>
                            </Col>
                            <Col md={3} className='lpsingle'>
                                <div>
                                    <Image src="assets/images/rr1.png" className='img-fluid' alt='rr1'/>
                                    <h3>CISSP vs Security+ Certifications: Which is Best in 2024?</h3>
                                    <p>When it comes to top cybersecurity certifications, CISSP and Security+ stan....</p>
                                    <Link to="/blog-detail">Agile</Link>
                                    <div>
                                        <p>By <span>Abhresh Sugandhi</span></p>
                                        <p>09 Oct 2024</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} className='lpsingle'>
                                <div>
                                    <Image src="assets/images/rr2.png" className='img-fluid' alt='rr2'/>
                                    <h3>30 PMP Formulas You Must Know to Pass the PMP Certification Exam</h3>
                                    <p>One question frequently comes to me when I talk to students preparing for th...</p>
                                    <Link to="/blog-detail">Agile</Link>
                                    <div>
                                        <p>By <span>Kevin D.Davis</span></p>
                                        <p>09 Oct 2024</p>
                                    </div>                                    
                                </div>
                            </Col>
                            <Col md={3} className='lpsingle'>
                                <div>
                                    <Image src="assets/images/rr3.png" className='img-fluid' alt='rr3'/>
                                    <h3>Pass AWS Certification Exams with benefits of AWS Whitepapers</h3>
                                    <p>A vital component of the IT industry in today's world is Amazon Web Services...</p>
                                    <Link to="/blog-detail">Agile</Link>
                                    <div>
                                        <p>By <span>Joydip Kumar</span></p>
                                        <p>09 Oct 2024</p>
                                    </div>                                    
                                </div>
                            </Col>
                            <Col md={3} className='lpsingle'>
                                <div>
                                    <Image src="assets/images/rr4.png" className='img-fluid' alt='rr4'/>
                                    <h3>30 PMP Formulas You Must Know to Pass the PMP Certification Exam</h3>
                                    <p>One question frequently comes to me when I talk to students preparing for th...</p>
                                    <Link to="/blog-detail">Agile</Link>
                                    <div>
                                        <p>By <span>Kevin D.Davis</span></p>
                                        <p>09 Oct 2024</p>
                                    </div>                                    
                                </div>
                            </Col>                                                                                    
                        </Row>
                        <Row>
                            <Col md={12} className='lpvm-btn'>
                                <Link>View More</Link>
                            </Col>
                        </Row>
                    </Container>
                </section>

            <Footer />

        </>
    )        

}
    export default Blog;    