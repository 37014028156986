import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {Row, Col, Image, Button} from 'react-bootstrap';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';


function CollapsibleExample() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);   
    
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 75) { 
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
        };
    
        window.addEventListener("scroll", handleScroll);
        
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    return (

    <>
    <section className='topbar'>
        <Container>
            <Row>
                <Col md={4} sm={4} className='phone'>
                <img src="assets/images/cnt-phone.svg" className="img-fluid" alt="country" />                
                <Link to="tel:911234567890">+91 123 4567 890</Link>
                </Col>
                <Col md={4} sm={4} className='mail'>
                    <img src="assets/images/mail.png" className="img-fluid" alt="mail" />                
                    <Link to="mailto:aglileseekers@gmail.com">aglileseekers@gmail.com</Link>
                </Col>
                <Col md={4} sm={4} className='social'>
                    <Link to="/"><img src="assets/images/facebook.png" className="img-fluid" alt="facebook" /></Link>
                    <Link to="/"><img src="assets/images/instagram.png" className="img-fluid" alt="instagram" /></Link>
                    <Link to="/"><img src="assets/images/linkedin.png" className="img-fluid" alt="linkedin" /></Link>
                    <Link to="/"><img src="assets/images/twitter.png" className="img-fluid" alt="twitter" /></Link>
                    <Link to="/"><img src="assets/images/youtube.png" className="img-fluid" alt="youtube" /></Link>
                </Col>
            </Row>
        </Container>
    </section>

    <section className='discountbar'>
        <Container>
            <Row>
                <Col className='discountbox'>
                    <p>Discount up to 70%</p>
                    <Link onClick={handleShow}><p>Grab Now</p></Link>
                </Col>
            </Row>
        </Container>
    </section>


{['lg'].map((expand) => (              
    <Navbar key={expand} expand={expand} collapseOnSelect id="agnav" className={`bg-body-tertiary ${isScrolled ? "scrolled" : ""}`}>
        <Container>
            <Navbar.Brand href="/"><img src="assets/images/logo.png" className="img-fluid" alt="logo" /></Navbar.Brand>
            <Nav className="me-auto mobhide">
                <NavDropdown title="All Courses" id="collapsible-nav-dropdown">
                    <NavDropdown.Item href="/course-detail">Course 1 Grow Personal Financial Security Thinking & Principles</NavDropdown.Item>
                    <NavDropdown.Item href="/course-detail">Course 2 Grow Personal Financial Security Thinking & Principles</NavDropdown.Item>
                    <NavDropdown.Item href="/course-detail">Course 3 Grow Personal Financial Security Thinking & Principles</NavDropdown.Item>
                    <NavDropdown.Item href="/course-detail">Course 4 Grow Personal Financial Security Thinking & Principles</NavDropdown.Item>
                    <NavDropdown.Item href="/course-detail">Course 5 Grow Personal Financial Security Thinking & Principles</NavDropdown.Item>
                    <NavDropdown.Item href="/course-detail">Course 6 Grow Personal Financial Security Thinking & Principles</NavDropdown.Item>
                </NavDropdown>
            </Nav>            

            {['lg'].map((ac) => (
              <Navbar key={ac} expand={ac} className="bg-body-tertiary me-auto ac-navbar deskhide">
                <Container fluid>
                  <Navbar.Toggle aria-controls={`offcanvasNavbar-ac-${ac}`}> 
                    <Image src="assets/images/acbar.png" className='img-fluid' alt='acbar'/>
                    All Courses
                    <Image src="assets/images/navdrop.png" className='img-fluid' alt='navdrop'/>
                  </Navbar.Toggle>
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-ac-${ac}`}
                    aria-labelledby={`offcanvasNavbarLabel-ac-${ac}`}
                    placement="start"
                  >
                    <Offcanvas.Header closeButton>
                      <img src="assets/images/logo.png" className="img-fluid" alt="logo" />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav className="justify-content-end flex-grow-1">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header><img src="assets/images/topic3.png" className="img-fluid" alt="category" /> Category 1</Accordion.Header>
                            <Accordion.Body>
                              <Col>
                                <Nav.Link href="/course-detail">Course 1 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 2 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 3 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 4 Grow Personal Financial Security Thinking & Principles</Nav.Link>    
                                <Nav.Link href="/course-detail">Course 5 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 6 Grow Personal Financial Security Thinking & Principles</Nav.Link>                            
                              </Col>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header><img src="assets/images/topic3.png" className="img-fluid" alt="category" /> Category 2</Accordion.Header>
                            <Accordion.Body>
                              <Col>
                                <Nav.Link href="/course-detail">Course 1 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 2 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 3 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 4 Grow Personal Financial Security Thinking & Principles</Nav.Link>    
                                <Nav.Link href="/course-detail">Course 5 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 6 Grow Personal Financial Security Thinking & Principles</Nav.Link>                            
                              </Col>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header><img src="assets/images/topic3.png" className="img-fluid" alt="category" /> Category 3</Accordion.Header>
                            <Accordion.Body>
                              <Col>
                                <Nav.Link href="/course-detail">Course 1 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 2 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 3 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 4 Grow Personal Financial Security Thinking & Principles</Nav.Link>    
                                <Nav.Link href="/course-detail">Course 5 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 6 Grow Personal Financial Security Thinking & Principles</Nav.Link>                            
                              </Col>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header><img src="assets/images/topic3.png" className="img-fluid" alt="category" /> Category 4</Accordion.Header>
                            <Accordion.Body>
                              <Col>
                                <Nav.Link href="/course-detail">Course 1 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 2 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 3 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 4 Grow Personal Financial Security Thinking & Principles</Nav.Link>    
                                <Nav.Link href="/course-detail">Course 5 Grow Personal Financial Security Thinking & Principles</Nav.Link>
                                <Nav.Link href="/course-detail">Course 6 Grow Personal Financial Security Thinking & Principles</Nav.Link>                            
                              </Col>
                            </Accordion.Body>
                          </Accordion.Item>                                                
                        </Accordion>                                    
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Container>
              </Navbar>
            ))}

            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <img src="assets/images/logo.png" className="img-fluid" alt="logo" />
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="ms-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/">Corporate</Nav.Link>
                    <Nav.Link href="/resources">Resources</Nav.Link>
                    <Nav.Link href="/blogs">Blogs</Nav.Link>
                    <Nav.Link href="/contact-us">Contact Us</Nav.Link>                                        
                </Nav>                
              </Offcanvas.Body>
            </Navbar.Offcanvas>            
        </Container>
    </Navbar>
))}    

    <Modal size="lg" show={show} onHide={handleClose} centered className='disc-modal' backdrop="static">
        <Modal.Body>
            <Row className='mx-0'>
                <Col md={6} className='di-mod-left'>                    
                    <Image src="assets/images/special-offer.png" className='img-fluid spof' alt='special-offer'/>
                    <Image src="assets/images/super-offer.png" className='img-fluid' alt='super-offer'/>
                </Col>
                <Col md={6} className='di-mod-right'>
                    <Link onClick={handleClose}><Image src="assets/images/close.png" className='img-fluid' alt='close'/></Link>
                    <Form>
                        <Form.Group className="mb-3" controlId="formGridPassword">
                            <Form.Label>Please Fill in the details below</Form.Label>
                            <Form.Control type="text" placeholder="Enter Full Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Control type="email" placeholder="Enter Your Email Id" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGridAddress2">
                            <Form.Control type="text" placeholder="Mobile Number" />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Claim Offer
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>

    </>
  );
}

export default CollapsibleExample;